<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="设备编号">
				<el-input v-model="form.deviceNo" placeholder="请输入设备编号"></el-input>
			</el-form-item>
			<el-form-item label="设备ID">
				<el-input v-model="form.deviceId" placeholder="请输入设备ID"></el-input>
			</el-form-item>
			<el-form-item label="设备类型">
				<el-select v-model="form.deviceTypeCode" placeholder="请选择设备类型">
					<el-option label="" value=""></el-option>
					<el-option v-for=" ( item , i ) in typeList " :key="i" :label="item.typeName" :value="item.typeCode"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="设备品牌">
				<el-input v-model="form.deviceBrand" placeholder="请输入设备品牌"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="device_list()" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
			<el-table-column prop="deviceId" label="设备ID" ></el-table-column>
			<el-table-column prop="home" label="所属家庭" ></el-table-column>
			<el-table-column prop="iccid" label="ICCID" width="220" >
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.iccid == '' ? 'info' : ''" disable-transitions>{{scope.row.iccid == '' ? '—' : scope.row.iccid}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="typeName" width="120" label="设备类型"></el-table-column>
			<el-table-column prop="deviceModel" width="120" label="设备型号名"></el-table-column>
			<el-table-column prop="deviceBrand" width="120" label="设备品牌" ></el-table-column>
			<el-table-column prop="status" width="120" label="设备状态" >
				<template slot-scope="scope">
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode == 6 && scope.row.status == 0 " type="success" disable-transitions>
						开阀
					</el-tag>
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode == 6 && scope.row.status == 1 " type="info" disable-transitions>
						关阀
					</el-tag>
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '—' " type="info" disable-transitions>
						{{scope.row.statusText}}
					</el-tag>
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '报警' " type="danger" disable-transitions>
						{{scope.row.statusText}}
					</el-tag>
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '正常' " type="success" disable-transitions>
						{{scope.row.statusText}}
					</el-tag>
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '在线-关机' " :type=" ( scope.row.deviceTypeCode != 1 && scope.row.deviceTypeCode != 7 && scope.row.deviceTypeCode != 8 ) ? 'success' : 'warning'" disable-transitions>
						<span>{{ ( scope.row.deviceTypeCode != 1 && scope.row.deviceTypeCode != 7 && scope.row.deviceTypeCode != 8 ) ? "在线" : scope.row.statusText }}</span>
					</el-tag>
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '在线-开机' " type="success" disable-transitions>
						<span>{{ ( scope.row.deviceTypeCode != 1 && scope.row.deviceTypeCode != 7 && scope.row.deviceTypeCode != 8 ) ? "在线" : scope.row.statusText }}</span>
					</el-tag>
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '在线' " type="success" disable-transitions>
						<span>{{ ( scope.row.deviceTypeCode != 1 && scope.row.deviceTypeCode != 7 && scope.row.deviceTypeCode != 8 ) ? "在线" : scope.row.statusText }}</span>
					</el-tag>
					<el-tag size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.statusText == '离线' " type="danger" disable-transitions>
						{{scope.row.statusText}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="lastUploadTime" label="最后上报时间">
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.lastUploadTime == null ? 'info' : ''" disable-transitions>{{scope.row.lastUploadTime == null ? '—' : scope.row.lastUploadTime}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="lastUploadTime" label="最后上线时间">
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.lastOnlineTime == null ? 'info' : ''" disable-transitions>{{scope.row.lastOnlineTime == null ? '—' : scope.row.lastOnlineTime}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="lastUploadTime" label="最后离线时间">
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.lastOfflineTime == null ? 'info' : ''" disable-transitions>{{scope.row.lastOfflineTime == null ? '—' : scope.row.lastOfflineTime}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="100">
			   <template slot-scope="scope">
					<el-button size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.deviceTypeCode != 5 && scope.row.statusOperator == '开关'" @click="sureIt(scope.row)" type="primary" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">开机/关机</el-button>
					<el-button size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.deviceTypeCode != 5 && scope.row.statusOperator == '在线-开机'" @click="changeButton(scope.row,2)" type="success" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">开机</el-button>
					<el-button size="mini" v-if=" scope.row.deviceTypeCode != 6 && scope.row.deviceTypeCode != 5 && scope.row.statusOperator == '在线-关机' " @click="changeButton(scope.row,2)" type="warning" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">关机</el-button>
					<el-button size="mini" v-if=" scope.row.deviceTypeCode == 5 && scope.row.statusText != '离线' && scope.row.statusText != '—' " @click="changeButton(scope.row,2)" type="warning" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">关机</el-button>
					<el-button size="mini" v-if=" scope.row.deviceTypeCode == 0 && scope.row.status != null" @click="gfCheck(scope.row)" type="danger" icon="el-icon-open" plain style="margin-right: 10px;margin-bottom: 5px;">关阀</el-button>
				</template>
			</el-table-column>
			<el-table-column label="查看" fixed="right" width="280">
			   <template slot-scope="scope">
					<el-button @click="historyNum(scope.row.deviceNo,1,scope.row.deviceTypeCode)" v-if="scope.row.deviceTypeCode != 6" size="mini" type="success" icon="el-icon-odometer" plain style="margin-right: 10px;margin-bottom: 5px;">历史数据</el-button>
					<el-button @click="historyNum(scope.row.deviceNo,2,scope.row.deviceTypeCode)" v-if="scope.row.deviceTypeCode != 6 || scope.row.deviceTypeCode == 0" size="mini" type="warning" icon="el-icon-share" plain style="margin-right: 10px;">历史指令</el-button>
					<el-button @click="historyNum(scope.row.deviceNo,3,scope.row.deviceTypeCode)" v-if="scope.row.deviceTypeCode == 6 || scope.row.deviceTypeCode == 0" size="mini" type="danger" icon="el-icon-warning-outline" plain>历史报警</el-button>
			    </template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="form.startPage" 
				:pageSize="form.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
		<historyData ref="historyData" @success="init_getTypeList" />
		<el-dialog
			title="提示"
			:visible.sync="dialogVisible"
			width="20%"
			@close="dialogVisible = false">
			<el-radio v-model="radio" label="1" style="margin-left: 20px;">开机</el-radio>
			<el-radio v-model="radio" label="0">关机</el-radio>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="changeButton(opendata , 1)" style="margin-right: 20px;">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog
			title="提示"
			:visible.sync="gfShow"
			width="20%"
			@close="gfShow = false">
			<div style="color: red;font-weight: bold;">是否确认关阀？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="gfSure" style="margin-right: 20px;">确 认</el-button>
				<el-button @click="gfShow = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import historyData from "./components/historyData.vue";
	export default{
		data(){
			return {
				loading: false,
				form : {
					deviceNo : "",
					startPage : 1,
					pageSize : 10,
					deviceId : "",
					deviceTypeCode : "",
					deviceBrand : ""
				},
				total : 0,
				list : [],
				typeList:[],
				upload:[],
				type:'',
				dialogVisible:false,
				opendata:{},
				changeType:0,
				radio: '0',
				gfShow:false,
				gfData:{}
			}
		},
		created(){
			//设置默认查询地址
			this.device_list();
		},
		mounted(){
			this.init_getTypeList();
		},
		onLoad(o){
			this.type = o.type
		},
		methods: {
			gfCheck(data){
				this.gfShow = true
				this.gfData = data
			},
			gfSure(){
				let data = {};
				data.deviceNo = this.gfData.deviceNo;
				data.deviceId = this.gfData.deviceId;
				this.$api.akxCloseValve( data ).then( res => {
					if( res.code == 200 ){
						this.$message({ type: 'success', message: res.data, });
						this.gfShow = false;
					}else{
						this.$message({ type: 'error', message: "操作失败", });
					}
				}).catch(res=>{
					this.$message({ type: 'error', message: "操作失败", });
				})
			},
			close() {
				this.dialogVisible = false;
			},
			sureIt( data ){
				this.dialogVisible = true;
				this.opendata = data;
			},
			changeButton( data , type ){
				// let upData = {
				// 	userName:this.user_info.userName,
				// 	deviceId:data.deviceId,
				// 	deviceTypeCode:data.deviceTypeCode,
				// }
				// if( type == 1 ){
				// 	upData.operationTypeCode = this.radio
				// }else{
				// 	if( data.deviceTypeCode == 5 ){
				// 		upData.operationTypeCode = '02'
				// 	}else{
				// 		if( data.statusOperator == '在线-开机'){
				// 			upData.operationTypeCode = '01'
				// 		}else if( data.statusOperator == '在线-关机' ){
				// 			upData.operationTypeCode = '02'
				// 		}
				// 	}
				// }
				let upData = {};
					upData.deviceId = data.deviceId;
					upData.deviceNo = data.deviceNo;
					upData.deviceTypeCode = data.deviceTypeCode;
					upData.deviceModel = data.deviceModel;
				let parameter = {};
				if( type == 1 ){
					parameter.isOpen = this.radio
				}else{
					if( data.deviceTypeCode == 5 ){
						parameter.isOpen = 0
					}else{
						if( data.statusOperator == '在线-开机'){
							parameter.isOpen = 1
						}else if( data.statusOperator == '在线-关机' ){
							parameter.isOpen = 0
						}
					}
				}
				upData.parameter = parameter;
				this.$api.setFunc_Rsq(upData).then(res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: "操作成功",
						});
						if( type == 1 ){
							this.dialogVisible = false;
						}
						this.device_list()
					}else{
						this.$message({
							type: 'error',
							message: "操作失败"
						});
					}
				});
			},
			init_getTypeList(){
				this.$api.device_getTypeList().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
					}else{
						this.$message({
							type: 'error',
							message: "获取设备类型失败"
						});
					}
				});
			},
			device_list(){
				this.loading = true;
				this.$api.device_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
						for( var i = 0 ; i < this.list.length ; i++ ){
							// 报警器设备-0:报警 1:正常 非报警器设备-0:在线-关机 1:在线-开机 -1:在线 -2:离线)
							this.list[i].statusOperator = ""
							if( this.list[i].deviceTypeCode == 0 ){
								if( this.list[i].status == 0 ){
									this.list[i].statusText = "报警"
								}else if( this.list[i].status == 1 ){
									this.list[i].statusText = "正常"
								}else{
									this.list[i].statusText = "—"
								}
							}else{
								if( this.list[i].status == 0 ){
									this.list[i].statusText = "在线-关机"
									this.list[i].statusOperator = "在线-开机"
								}else if( this.list[i].status == 1 ){
									this.list[i].statusText = "在线-开机"
									this.list[i].statusOperator = "在线-关机"
								}else if( this.list[i].status == -1 ){
									this.list[i].statusText = "在线"
									this.list[i].statusOperator = "开关"
								}else if( this.list[i].status == -2 ){
									this.list[i].statusText = "离线"
								}else{
									this.list[i].statusText = "—"
								}
							}
						}
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			changePage( e ){
				this.form.startPage = e;
				this.device_list();
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.device_list();
			},
			historyNum( deviceNo , type , deviceTypeCode ){
				this.$refs.historyData.open( deviceNo , type , deviceTypeCode );
			}
		},
		components:{
			historyData
		}
	}
</script>
