<template>
	<!-- <el-dialog destroy-on-close :close-on-click-modal="false" @close="close" ref="dialog" :title="title" :visible.sync="show" width="1000px" > -->
	<el-dialog destroy-on-close :close-on-click-modal="false" @close="close" ref="dialog" :title="title" :visible.sync="show" width="1400px">
		<div style="height: 600px;">
			<el-form :inline="true" :model="form" ref="form">
				<el-form-item label="时间范围">
				  <el-date-picker
				      value-format="yyyy-MM-dd"
				      v-model="date_value"
				      type="daterange"
				      range-separator="至"
				      start-placeholder="开始日期"
				      end-placeholder="结束日期">
				  </el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button @click="device_list()" type="primary">查询</el-button>
				</el-form-item>
			</el-form>
			<div v-if=" type == 1 " style="width: 100%;height: 500px;overflow: auto;">
				<el-table :data="list1" border style="width: 100%;" v-show=" code != 0" v-loading="loading">
					<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
					<el-table-column prop="reportData" label="上报数据" ></el-table-column>
					<el-table-column prop="deviceType" label="设备类型"></el-table-column>
					<el-table-column prop="transferSource" label="传送来源" ></el-table-column>
					<el-table-column prop="transferTarget" label="传送目标" ></el-table-column>
					<el-table-column prop="deviceState" label="设备状态" >
						<template slot-scope="scope">
							<el-tag :type="scope.row.deviceState == null ? 'info' : scope.row.deviceState == 0 ? 'danger' : 'success'" disable-transitions>{{scope.row.deviceState == null ? '—' : scope.row.deviceState == 0 ? '关机' : '开机'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="protocolVersion" label="主控器协议版本" ></el-table-column>
					<el-table-column prop="programVersion" label="主控器程序版本"></el-table-column>
					<el-table-column prop="programDate" label="主控器程序日期" ></el-table-column>
					<el-table-column prop="model" label="主控器型号" ></el-table-column>
					<el-table-column prop="airType" label="主控器气种" ></el-table-column>
					<el-table-column prop="litres" label="主控器升数"></el-table-column>
					<el-table-column prop="rtc" label="主控器RTC时钟" ></el-table-column>
					<el-table-column prop="createTime" label="上报时间" ></el-table-column>
				</el-table>
				<el-table :data="list1" border style="width: 100%" v-show=" code == 0 " v-loading="loading">
					<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
					<el-table-column prop="reportData" label="上报数据" ></el-table-column>
					<el-table-column prop="deviceName" label="设备名称"></el-table-column>
					<el-table-column prop="uuid" label="设备UUID" ></el-table-column>
					<el-table-column prop="code" label="命令码" width="165px">
						<template slot-scope="scope">
							<el-tag :type="scope.row.code == '1f' ? '' : 'success'" disable-transitions>{{ scope.row.code == '1f' ? '设备上电数据上报' :  '无线模块在线数据上报'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="deviceType" label="设备类型" ></el-table-column>
					<el-table-column prop="ch4State" label="甲烷传感器状态" ></el-table-column>
					<el-table-column prop="ch4Concentration" label="甲烷浓度(单位%LEL)"></el-table-column>
					<el-table-column prop="coState" label="一氧化碳传感器状态" ></el-table-column>
					<el-table-column prop="coConcentration" label="一氧化碳浓度(单位PPM)" ></el-table-column>
					<el-table-column prop="outputStatus" label="输出状态" ></el-table-column>
					<el-table-column prop="rsrp" label="RSRP"></el-table-column>
					<el-table-column prop="snr" label="SNR" ></el-table-column>
					<el-table-column prop="csq" label="信号强度CSQ" ></el-table-column>
					<el-table-column prop="createTime" label="上报时间" ></el-table-column>
				</el-table>
			</div>
			<div v-if="type==2" style="width: 100%;height: 500px;overflow: auto;">
				<el-table :data="list2" border style="width: 100%;" v-loading="loading">
					<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
					<el-table-column prop="deviceId" label="设备ID" ></el-table-column>
					<el-table-column prop="commandId" label="指令ID"></el-table-column>
					<el-table-column prop="commandStr" label="指令" ></el-table-column>
					<el-table-column prop="sendTime" label="指令下发时间" ></el-table-column>
					<el-table-column prop="responseTime" label="指令响应时间" ></el-table-column>
					<el-table-column prop="resultCode" label="指令响应结果" ></el-table-column>
				</el-table>
			</div>
			<div v-if="type==3" style="width: 100%;height: 500px;overflow: auto;">
				<el-table :data="list3" border style="width: 100%;" v-loading="loading">
					<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
					<el-table-column prop="ch4State" label="甲烷传感器状态" ></el-table-column>
					<el-table-column prop="ch4Concentration" label="甲烷浓度(单位%LEL)"></el-table-column>
					<el-table-column prop="coState" label="一氧化碳传感器状态" ></el-table-column>
					<el-table-column prop="coConcentration" label="一氧化碳浓度(单位%PPM)" ></el-table-column>
					<el-table-column prop="content" label="报警消息内容" ></el-table-column>
					<el-table-column prop="createTime" label="报警时间" ></el-table-column>
				</el-table>
			</div>
			<div class="page mg_t text_c">
				<el-pagination 
					@current-change="changePage"
					@size-change="sizeChange"
					:page-sizes="[ 10 , 20 , 30 , 40]" 
					:total="total" 
					:currentPage="form.startPage" 
					:pageSize="form.pageSize" 
					background
					layout="total, sizes, prev, pager, next, jumper"
				>
				</el-pagination>
			</div>
		</div>
		
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return {
				loading: false,
				form : {
					startPage : 1,
					pageSize : 10,
					deviceNo : "",
					startDate:'',
					endDate:'',
				},
				total : 0,
				list1 : [],
				list2 : [],
				list3 : [],
				show : false,
				type:"",
				title:"",
				date_value:'',
				code:100
			}
		},
		methods: {
			open( deviceNo , type , code ){
				this.form.deviceNo = deviceNo;
				this.type = type;
				if( type == 1 ){
					this.title = "历史数据"
				}else if( type == 2 ){
					this.title = "历史指令"
				}else{
					this.title = "历史报警"
				}
				this.show = true;
				this.code = code;
				this.device_list()
			},
			device_list(){
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					this.form.startDate = this.date_value[0];
					this.form.endDate = this.date_value[1];
				}else{
					this.form.startDate = "";
					this.form.endDate = "";
				}
				this.loading = true;
				if( this.type == 1 ){
					this.form.deviceTypeCode = this.code;
					this.$api.deviceData_list( this.form ).then( res => {
						if( res.code == 200 ){
							this.list1 = res.data.list;
							this.form.startPage = res.data.page;
							this.total = res.data.totalNum;
							this.loading = false;
							// for( var i = 0 ; i < this.list1.length ; i++ ){
							// 	for( var k in this.list1[i] ){
							// 		if( this.list1[i][k] == null || this.list1[i][k] == "" ){
							// 			this.list1[i][k] = "—"
							// 		}
							// 	}
							// }
						}else{
							this.list1 = [];
							this.form.startPage = 1;
							this.total = 0;
							this.loading = false;
						}
					});
				}else if( this.type == 2 ){
					this.$api.command_list( this.form ).then( res => {
						if( res.code == 200 ){
							this.list2 = res.data.list;
							for( var i = 0 ; i < this.list2.length ; i++ ){
								for( var k in this.list2[i] ){
									if( this.list2[i][k] == null || this.list2[i][k] == "" ){
										this.list2[i][k] = "—"
									}
								}
							}
							this.form.startPage = res.data.page;
							this.total = res.data.totalNum;
							this.loading = false;
						}else{
							this.list2 = [];
							this.form.startPage = 1;
							this.total = 0;
							this.loading = false;
						}
					});
				}else{
					this.$api.alarm_list(this.form).then(res => {
						if (res.code == 200) {
							this.list3 = res.data.list;
							this.form.startPage = res.data.page;
							this.total = res.data.totalNum;
							this.loading = false;
							for( var i = 0 ; i < this.list3.length ; i++ ){
								for( var k in this.list3[i] ){
									if( this.list3[i][k] == null || this.list3[i][k] == "" ){
										this.list3[i][k] = "—"
									}
								}
							}
						} else {
							this.list3 = [];
							this.form.startPage = 1;
							this.total = 0;
							this.loading = false;
						}
					});
				}
			},
			changePage( e ){
				this.form.startPage = e;
				this.device_list();
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.device_list();
			},
			close( done ){
				this.$refs.form.resetFields();
				this.date_value = ""
			},
		},
	}
</script>

<style lang="scss">
	.box {
		height: 25px;
	}

	.el-dialog__header {
		background: linear-gradient(135deg, #6b6be4, #15d8f1);
	}

	.el-dialog__title {
		color: #fff
	}

	.el-dialog__header {
		.el-dialog__headerbtn {
			.el-dialog__close {
				color: #fff;
			}
		}
	}
</style>